<template>
  <div :style="{ position: 'relative' }">
    <Loading :active.sync="loading" :isFullPage="false"></Loading>
    <ul>
      <StreakElement
        v-for="(streakState, index) in streakStates"
        :key="index"
        :backgroudColor="streakState.backgroudColor"
        :textColor="streakState.textColor"
        :active="streakState.active"
        :title="streakState.title"
        :subtitle="streakState.subtitle"
        :counter="streakState.counter"
        @click.native="changeActive(index)"
      />
    </ul>
  </div>
</template>
<script>
import StreakElement from "./W4MstreakElement.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "W4Mstreak",
  props: ["states", 'loader'],
  components: {
    StreakElement,
    Loading,
  },
  watch: {
    states() {
      this.streakStates = this.states;
    },
    loader() {
      if (this.loader == undefined) {
        return;
      }
      if (this.loader == true || this.loader == false) {
        return this.loading = this.loader;
      }
    }
  },
  data() {
    return {
      streakStates: this.states,
      loading: false,
    };
  },
  methods: {
    changeActive(index) {
      this.streakStates.forEach((state, i) => {
        if (index != i) {
          return (state.active = false);
        } else if (index == i && state.active == false) {
          state.active = true;
          return this.$emit("W4MStreakChangeSelected", {
            currentState: state.title,
            currentStateIndex: index,
          });
        }
      });
    },
  },
};
</script>
<style scoped>
ul li {
  display: inline-block;
  height: 50px;
  line-height: 55px;
  margin: 5px 2px 0 0;
  text-indent: 35px;
  position: relative;
  cursor: grab;
  margin-top: 10px;
}

ul li:first-child:before {
  border-color: transparent;
}
</style>
