<template>
  <div class="card p-shadow-6">
    <h1>Inquéritos de Satisfação - Instalações</h1>
    <div class="p-d-flex p-jc-center">
      <W4Mstreak
        :states="streakStatus"
        v-on:W4MStreakChangeSelected="streakSelectedChange"
      />
    </div>
    <div style="height: 65vh">
      <DataTable
        :value="installationsList"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        sortField="name"
        :sortOrder="1"
        responsiveLayout="scroll"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #empty> Nada a mostrar. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <template #header>
          <div class="table-header">
            <label for="year-selector" class="p-mr-1 p-my-auto">Ano</label>
            <InputNumber
              id="year-selector"
              class="p-ml-2 p-mr-2"
              :value="selectedYear"
              showButtons
              buttonLayout="stacked"
              inputStyle="width:65px"
              :step="1"
              :min="2022"
              :max="new Date().getFullYear()"
              :useGrouping="false"
              @input="yearChanged"
            />

            <Button
              v-if="$store.state.auth.user.isEsa"
              icon="pi pi-download"
              label="Exportar Inquéritos"
              class="p-button-success p-mr-1 p-button-sm"
              @click="exportForms()"
            />
          </div>
        </template>
        <Column field="id" header="Id" sortable filterField="id">
          <template #body="slotProps">
            <span class="p-column-title">Id:</span>
            {{ slotProps.data.id }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column field="name" header="Nome" sortable filterField="name">
          <template #body="slotProps">
            <span class="p-column-title">Nome:</span>
            {{ slotProps.data.name }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="description"
          header="Descrição"
          sortable
          filterField="description"
        >
          <template #body="slotProps">
            <span class="p-column-title">Descrição:</span>
            {{ slotProps.data.description }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="date_installation_date"
          header="Data Instalação"
          sortable
          filterField="date_installation_date"
          dataType="date"
        >
          <template #body="slotProps">
            {{ slotProps.data.installation_date }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column>
        <Column field="seller" header="Vendedor" sortable filterField="seller">
          <template #body="slotProps">
            <span class="p-column-title">Vendedor:</span>
            {{ slotProps.data.seller }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column field="manager" header="Gestor" sortable filterField="manager">
          <template #body="slotProps">
            <span class="p-column-title">Gestor:</span>
            {{ slotProps.data.manager }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="responsible_technical"
          header="Responsável"
          sortable
          filterField="responsible_technical"
        >
          <template #body="slotProps">
            <span class="p-column-title">Responsável:</span>
            {{ slotProps.data.responsible_technical }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="satisfaction_forms"
          header="Respondido"
          filterField="satisfaction_forms"
          dataType="boolean"
          sortable
        >
          <template #body="slotProps">
            <span class="p-column-title">Respondido:</span>
            {{ slotProps.data.satisfaction_forms ? "Sim" : "Não" }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { value: false, label: 'Não' },
                { value: true, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
        </Column>

        <Column>
          <template #body="slotProps">
            <Button
              v-if="!slotProps.data.satisfaction_forms"
              v-tooltip.left="'Adicionar Inquério'"
              icon="pi pi-plus"
              class="p-button-rounded p-button-success p-button-outlined"
              @click="goToForm(slotProps.data.id)"
            />
            <Button
              v-else
              v-tooltip.left="'Ver Inquérito'"
              icon="pi pi-eye"
              class="p-button-rounded p-button-success p-button-outlined"
              @click="goToForm(slotProps.data.id)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import satisfactionFormService from "../services/satisfactionForm.service";
import W4Mstreak from "../components/W4Mstreak/W4Mstreak.vue";
import { FilterMatchMode } from "primevue/api/";

export default {
  name: "SatisfactionFormInstallations",
  components: {
    W4Mstreak,
  },
  data() {
    return {
      loading: true,
      installationsList: [],
      selectedYear: new Date().getFullYear(),
      filters: {
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        seller: { value: null, matchMode: FilterMatchMode.CONTAINS },
        manager: { value: null, matchMode: FilterMatchMode.CONTAINS },
        responsible_technical: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        satisfaction_forms: { value: null, matchMode: FilterMatchMode.EQUALS },
        date_concluision_date: {
          value: null,
          matchMode: FilterMatchMode.DATE_IS,
        },
        date_installation_date: {
          value: null,
          matchMode: FilterMatchMode.DATE_IS,
        },
      },
      streakStatus: [],
      answered: [],
      toAnswered: [],
      all: [],
      activeStatus: "Por Responder",
    };
  },
  created() {
    this.loading = true;
    this.getInstallations();
  },
  methods: {
    yearChanged(year) {
      if (this.selectedYear == year) {
        return;
      }
      this.selectedYear = year;
      return this.getInstallations();
    },
    getInstallations() {
      this.loading = true;
      return satisfactionFormService
        .getInstallations(this.$store.state.auth.user.id, this.selectedYear)
        .then((response) => {
          response.forEach((element) => {
            element["date_concluision_date"] = new Date(
              element.concluision_date
            );
            if (element.concluision_date == "1900-01-01") {
              element.concluision_date = "";
            }
            element["date_installation_date"] = new Date(
              element.installation_date
            );
            if (element.installation_date == "1900-01-01") {
              element.installation_date = "";
            }
          });
          this.loading = false;
          this.all = response;
          this.answered = this.all.filter(
            (installation) => installation.satisfaction_forms == true
          );
          this.toAnswered = this.all.filter(
            (installation) => installation.satisfaction_forms == false
          );
          if (this.activeStatus == "Respondido") {
            this.installationsList = this.answered;
          } else if (this.activeStatus == "Por Responder") {
            this.installationsList = this.toAnswered;
          } else {
            this.installationsList = this.all;
          }
          return this.initStreakStatus();
        });
    },
    goToForm(installationId) {
      let route = this.$router.resolve({
        path: `/satisfaction-form-installations-quiz/${installationId}`,
      });
      window.open(route.href, "_blank");
    },
    exportForms() {
      let route = this.$router.resolve({
        path: `/satisfaction-form-installations-export`,
      });
      window.open(route.href, "_blank");
    },
    initStreakStatus() {
      let count = this.countStatus();
      this.streakStatus = [
        {
          backgroudColor: "#38bbea",
          textColor: "#000",
          title: "Total",
          counter: count.total,
          active: this.activeStatus == "Total",
        },
        {
          backgroudColor: "#00ff87",
          textColor: "#000",
          title: "Respondido",
          counter: count.answered,
          active: this.activeStatus == "Respondido",
        },
        {
          backgroudColor: "#E1C800",
          textColor: "#000",
          title: "Por Responder",
          counter: count.toAnswered,
          active: this.activeStatus == "Por Responder",
        },
      ];
    },
    countStatus() {
      return {
        total: this.all.length,
        answered: this.answered.length,
        toAnswered: this.toAnswered.length,
      };
    },
    streakSelectedChange(selected) {
      this.loading = true;
      this.activeStatus = selected.currentState;
      switch (selected.currentState) {
        case "Total":
          this.installationsList = this.all;
          break;
        case "Respondido":
          this.installationsList = this.answered;
          break;
        case "Por Responder":
          this.installationsList = this.toAnswered;
          break;
      }
      return (this.loading = false);
    },
  },
};
</script>
<style scoped lang="scss">
.p-column-filter {
  width: 100%;
}
</style>
