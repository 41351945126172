<template>
  <li :style="{ width: $isMobile() ? '85%' : '' }">
    <div class="content">
      <span class="counter">{{ counter }}</span>
      <span class="titles">
        <span v-bind:class="['title', { active: active }]">{{ title }}</span>
        <span v-if="subtitle" class="subtitle">{{ subtitle }}</span>
      </span>
    </div>
  </li>
</template>

<script>
export default {
  name: "W4MstreakElement",
  props: [
    "title",
    "backgroudColor",
    "textColor",
    "active",
    "counter",
    "subtitle",
  ],
};
</script>
<style scoped>
.counter {
  font-weight: bold;
  font-size: 25px;
}

.title {
  margin-left: -20px;
  margin-right: 15px;
  line-height: 20px;
  font-size: 16px;
}
.subtitle {
  margin-left: -20px;
  margin-right: 12px;
  line-height: 12px;
  color: rgb(94, 94, 94);
}

li:before {
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  left: 0px;
  border-style: solid;
  border-width: 30px 0 25px 25px;
  border-color: transparent transparent transparent #fff;
  z-index: 0;
}

li:not(:last-child) div:after {
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  right: -24px;
  border-style: solid;
  border-width: 30px 0 25px 25px;
  border-color: transparent transparent transparent v-bind(backgroudColor);
  z-index: 10;
}

.title.active {
  font-weight: bold;
  font-size: 16px;
  z-index: 100;
}

li div {
  display: block;
  background: v-bind(backgroudColor);
  color: v-bind(textColor);
}

.title:hover {
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
}

.title:hover:after {
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
}

.active {
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
}

.content {
  display: flex;
  flex-direction: row;
}

.titles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
</style>
